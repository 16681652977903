import FetchInterceptor from "configs/FetchInterceptor";

class BomServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/bill-of-materials/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async add(requestBody) {
    const apiUrl = "/core/v1/bill-of-materials/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default BomServices;
